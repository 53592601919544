<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Detail Customer Return</h4>
    </span>

    <h5>Approve Info</h5>
    <hr />
    <div class="vx-row mb-2">
      <div class="vx-col my-2 w-full">
        <vs-textarea class="w-full" label="Feedback" v-model="feedback" />
      </div>
      <div class="vx-col my-2 w-full">
        <label class="vs-input--label">Choose status</label>
        <multiselect
          v-model="selectedStatus"
          :options="status"
          track-by="value"
          label="label"
          :max-height="125"
          :allow-empty="true"
          :select-label="''"
          deselect-label=""
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.label }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.label }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col my-2 w-full flex gap-2">
        <vs-button color="primary" type="filled" @click="submitApproval"
          >Submit</vs-button
        >
        <vs-button
          color="success"
          type="filled"
          size="small"
          @click="clickApprovalHistory(id)"
          >Approval Histories</vs-button
        >
      </div>
    </div>

    <div>
      <h5>Sales Return SO Type</h5>
      <hr />
      <div class="vx-row mb-2">
        <div class="vx-col my-2 w-full">
          <vs-input class="w-full input-readonly" readonly v-model="so_type" />
        </div>
      </div>

      <h5>Customer Invoice Data</h5>
      <hr />
      <div class="vx-row mb-2">
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Invoice Code"
            readonly
            v-model="form.customer_invoice_data.invoice_code"
          />
        </div>
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Sales"
            v-model="form.customer_invoice_data.sales_id_name"
            readonly
          />
        </div>
      </div>

      <h5>Customer Data</h5>
      <hr />
      <div class="vx-row mb-2">
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Customer Code"
            readonly
            v-model="form.customer_data.customer_code"
          />
        </div>
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Customer Name"
            v-model="form.customer_data.customer_name"
            readonly
          />
        </div>
        <div class="vx-col my-2 w-full">
          <vs-input
            class="w-full input-readonly"
            label="Pickup From / Ship To"
            :value="form.customer_return_data.customer_ship_to_id == 0 ? form.customer_data.pickup_from_ship_to : (form.customer_return_data.customer_ship_to_code + ' - ' + form.customer_return_data.customer_ship_to_name + ' - ' + form.customer_return_data.customer_ship_to_address)"
            readonly
          />
        </div>
      </div>

      <h5>Customer Return Data</h5>
      <hr />
      <div class="vx-row mb-2">
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Reference Code"
            readonly
            v-model="form.customer_return_data.reference_code"
          />
        </div>
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Reason Code"
            v-model="form.customer_return_data.reason_code"
            readonly
          />
        </div>
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Customer Return Type"
            v-model="form.customer_return_data.customer_return_type"
            readonly
          />
        </div>
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Territory Warehouse"
            v-model="form.customer_return_data.territory_warehouse"
            readonly
          />
        </div>
        <div class="vx-col my-2 w-full">
          <vs-textarea
            class="w-full input-readonly"
            label="Notes"
            v-model="form.customer_return_data.note"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <label>List of Attachment</label>
          <ul class="flex gap-2">
            <li
              v-for="(item, index) in form.customer_return_data.attachments"
              :key="index"
            >
              <vs-button
                class="mb-2"
                color="primary"
                type="filled"
                size="small"
                @click="clickAttachment(item)"
              >
                {{ `Attachment ${index + 1}` }}
              </vs-button>
            </li>
          </ul>
        </div>
      </div>

      <h5>Pickup Request Data</h5>
      <hr />
      <div class="vx-row mb-2">
        <div class="vx-col my-2 w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Pickup Date"
            readonly
            type="date"
            v-model="form.pickup_request_data.pickup_date"
            :disabled=true
          />
        </div>
        <div class="vx-col my-2 w-1/2">
          <label for="">Is Pickup</label>
          <vs-checkbox
            class="w-full input-readonly"
            label="Is Pickup"
            readonly
            v-model="form.pickup_request_data.is_pickup"
            :disabled=true
          />
        </div>
      </div>

      <h5>Line Items</h5>
      <hr />
      <div class="vx-row mb-2">
        <div class="vx-col my-2 w-full">
          <vs-table :data="form.line_item_data">
            <template slot="thead">
              <vs-th> No. </vs-th>
              <vs-th> Sku Code </vs-th>
              <vs-th> Name </vs-th>
              <vs-th> Qty </vs-th>
              <vs-th> HU </vs-th>
              <vs-th> Price </vs-th>
              <vs-th> Adj. Price </vs-th>
              <!-- <vs-th> Reg Discount </vs-th> -->
              <vs-th> Tax </vs-th>
              <vs-th> Grand Total </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ indextr + 1 }}</vs-td>
                <vs-td>{{ tr.item_sku_code }}</vs-td>
                <vs-td>{{ tr.item_name }}</vs-td>
                <vs-td>{{ tr.quantity }}</vs-td>
                <vs-td>{{ tr.item_unit }}</vs-td>
                <vs-td class="text-right">{{ formatCurrencyIDR(tr.price) }}</vs-td>
                <vs-td class="text-right">{{ formatCurrencyIDR(tr.adj_price) }}</vs-td>
                <!-- <vs-td>{{ formatCurrencyIDR(tr.reg_discount) }}</vs-td> -->
                <vs-td class="text-right">{{ formatCurrencyIDR(tr.tax) }}</vs-td>
                <vs-td class="text-right">{{ formatCurrencyIDR(tr.grand_total) }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- return summary -->
        <vs-row class="flex my-2">
          <vs-col vs-w="4" vs-offset="8">
            <p class="text-xl">Return Summary</p>
            <hr />

            <div class="my-2 text-right">
              <label>Total List Price</label>
              <br />
              <h5 class="font-bold">
                {{
                  formatCurrencyIDR(
                    parseFloat(this.total_line.list_price)
                  )
                }}
              </h5>
            </div>

            <div class="my-2 text-right">
              <label>Total Adj. Price</label>
              <br />
              <h5 class="font-bold">
                {{
                  formatCurrencyIDR(
                    parseFloat(this.total_line.adj_price)
                  )
                }}
              </h5>
            </div>
            <div class="my-2 text-right">
              <label>Total Tax</label>
              <br />
              <h5 class="font-bold">
                {{ formatCurrencyIDR(parseFloat(this.total_line.tax)) }}
              </h5>
            </div>

             <!--  -->
            <div class="my-2 text-right">
              <label>Total Sales Return</label>
              <br />
              <h5 class="font-bold">
                {{ formatCurrencyIDR(parseFloat(this.total_line.grand_total)) }}
              </h5>
            </div>
          </vs-col>
        </vs-row>

          <!--  -->
        </div>
      </div>
    </div>

    <!-- pop up approval history -->
    <vs-popup
      title="Approval Histories"
      :active.sync="activePopUpApprovalHistory"
    >
      <TableApprovalLine
        ref="tableApprovalLine"
        :filter="filterTableApprovalLine"
      />
    </vs-popup>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const SalesReturnV2Repo = RepositoryFactory.get("salesReturnV2");
import { formatCurrencyIDR } from "@/helpers/common";
import TableApprovalLine from "@/components/approvalLine/Table.vue";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    TableApprovalLine,
  },
  mounted() {
    this.getDetail(this.id);
  },
  data() {
    return {
      activePopUpApprovalHistory: false,
      filterTableApprovalLine: {
        approval_reference_id: 0,
        code_approval_type: "CR",
      },
      form: {},
      total_line: {
        list_price: 0,
        adj_price: 0,
        tax: 0,
        grand_total: 0,
      },
      feedback: "",
      selectedStatus: "",
      status: [
        { label: "Approve", value: 1 },
        { label: "Reject", value: 6 },
        { label: "Inquiry", value: 12 },
      ],
    };
  },
  methods: {
    clickApprovalHistory(id) {
      this.activePopUpApprovalHistory = true;
      this.filterTableApprovalLine.approval_reference_id = id;
      this.$nextTick(() => {
        this.$refs.tableApprovalLine.getData();
      });
    },
    formatCurrencyIDR,
    async submitApproval() {
      const status = this.selectedStatus.value;
      // console.log("status", status);
      // console.log("this.selectedStatus", this.selectedStatus);
      // console.log("this.selectedStatus == 'string kosong'", (this.selectedStatus == ""));
      if (this.selectedStatus == "" || this.selectedStatus.value == undefined) {
        // Status approve belum dipilih
        this.$vs.notify({
          title: "Error",
          text: "Please select approval status !",
          color: "danger",
        });
      } else {
        this.$vs.loading();
        try {
          const status = this.selectedStatus.value;
  
          const resp = await SalesReturnV2Repo.doApprovalStatus(
            this.id,
            status,
            this.feedback
          );
  
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: resp.message,
              color: "success",
            });
            this.$emit("close");
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
            });
          }
        } catch (error) {
          this.$vs.notify({
            title: "Error",
            text: error.message,
            color: "danger",
          });
        }
        this.$vs.loading.close();
      }
    },
    async clickAttachment(item) {
      window.open(item, "_blank");
    },
    async getDetail(id) {
      this.$vs.loading();
      try {
        const resp = await SalesReturnV2Repo.getApprovalDetail(id);
        if (resp.code == 200) {
          this.form = resp.data;

          this.so_type =
            resp.data.customer_invoice_data.invoice_code != ""
              ? "With SO Ref"
              : "Without SO Ref";
          
          // Prepare additional show data, adj Price, tax
          this.form.line_item_data.forEach((item, i) => {
            // console.log(" li, i ", li, i)
            // So return without ref
            if (resp.data.customer_invoice_data.invoice_code == "") {
              this.form.line_item_data[i].tax = item.tax_rate > 0 ? Math.floor((item.adj_price * item.quantity) * item.tax_rate / 100) : this.form.line_item_data[i].tax;
            }

            // Grand total
            this.form.line_item_data[i].grand_total = (item.adj_price * item.quantity) + this.form.line_item_data[i].tax;

            // Add totals
            this.total_line.list_price += (item.price * item.quantity);
            this.total_line.adj_price += (item.adj_price * item.quantity);
            this.total_line.tax += this.form.line_item_data[i].tax;
            this.total_line.grand_total += this.form.line_item_data[i].grand_total;
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
        });
      }
      this.$vs.loading.close();
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
