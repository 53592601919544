<template>
  <vx-card title="Approval Customer Return">
    <vs-row class="mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-col>
          <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
            @change-page="handleChangePage"
          >
            <template slot="header">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{ this.table.start }} - {{ this.table.end }} of
                    {{ this.table.total }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="item in table.limits"
                    :key="item"
                    @click="handleChangelength(item)"
                  >
                    <span>{{ item }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </template>
            <template slot="thead">
              <vs-th>Action</vs-th>
              <vs-th>Customer Return Data</vs-th>
              <vs-th>Customer Data</vs-th>
              <vs-th>Invoice Data</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-button
                    color="success"
                    type="filled"
                    size="small"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click="clickDetail(tr.id)"
                  />
                </vs-td>
                <vs-td>
                  <p>
                    <b>Customer Return Code: </b> {{ tr.customer_return_code }}
                  </p>
                  <p><b>Reference Code: </b> {{ tr.reference_code }}</p>
                  <p>
                    <b>Date: </b>
                    {{ dateFormat(tr.customer_return_date, "DD-MM-YYYY") }}
                  </p>

                  <p><b>Reason: </b> {{ tr.reason }}</p>

                  <p>
                    <b>Customer Return Type: </b> {{ tr.customer_return_type }}
                  </p>

                  <p><b>Territory: </b> {{ tr.territory }}</p>

                  <p><b>Note: </b> {{ tr.note }}</p>
                </vs-td>
                <vs-td>
                  <p><b>Customer Code: </b> {{ tr.customer_code }}</p>

                  <p><b>Customer Name: </b> {{ tr.customer_name }}</p>

                  <p><b>ShipTo: </b> {{ tr.ship_to_id == 0 ? tr.ship_to : (tr.ship_to_code + " - " + tr.ship_to_name + " - " + tr.ship_to_address) }}</p>
                </vs-td>
                <vs-td>
                  <p><b>Invoice Code: </b> {{ tr.invoice_code }}</p>

                  <p>
                    <b>Invoice Date: </b>
                    {{ dateFormat(tr.invoice_date, "DD-MM-YYYY") }}
                  </p>

                  <p><b>Sales Order Code: </b> {{ tr.sales_order_code }}</p>

                  <p>
                    <b>Sales Order Date: </b>
                    {{ dateFormat(tr.sales_order_date, "DD-MM-YYYY") }}
                  </p>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            style="padding-top: 5px"
            :total="table.totalPage"
            v-model="setPage"
          />
        </vs-col>
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <detail @close="handleClose" :id="selectedID"> </detail>
          </vs-row>
        </div>
      </transition>
    </vs-row>
  </vx-card>
</template>
<script>
import Detail from "./detail.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const SalesReturnV2Repo = RepositoryFactory.get("salesReturnV2");
import { dateFormat } from "@/helpers/common";

export default {
  components: {
    Detail,
  },
  data() {
    return {
      table: this.tableDefaultState(),
      selecteds: [],
      detail: false,
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      selectedID: null,
    };
  },
  methods: {
    async clickDetail(id) {
      this.detail = true;
      this.selectedID = id;
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    dateFormat,
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        status: 1,
        is_approval_list: true,
      };
    },
    async handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      await this.getData();
    },
    async handleChangePage(page) {
      this.table.page = page;
      await this.getData();
    },
    async handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      await this.getData();
    },
    async handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      await this.getData();
    },
    async getData() {
      await this.$vs.loading();
      try {
        const params = {
          ...this.table,
        };
        // remove data
        delete params.data;
        const resp = await SalesReturnV2Repo.getList({
          params: params,
        });
        if (resp.code == 200) {
          this.table.data = resp.data.records;
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          await this.setStartEnd();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
        });
      }
      await this.$vs.loading.close();
    },
    async setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
